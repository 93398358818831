/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 9/03/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */
import ListResult from "../../../types/ListResult";
import Transaction from "../../../types/Transaction";
import get from "./util/get";

function getTransactions(params?: {
    user?: string | null;
    limit?: number;
    type?: string | null;
    from?: string | null;
    to?: string | null;
    cursor?: string | null;
    order?: string | null;
    status?: string | null;
}): Promise<ListResult<Transaction>> {
    return get(`/v2/admin/transactions`, {
        params: params,
    });
}

const TransactionsAPI = {
    getTransactions: getTransactions,
};

export default TransactionsAPI;