/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 4/03/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import Newsletter from "../../types/Newsletter";
import NewslettersAPI from "./api/newsletters";
import createAction from "./helpers/createAction";
import wrapper from "./helpers/wrapper";

export enum Types {
    SET_NEWSLETTERS = "newsletters:SET_NEWSLETTERS",
}

export const setNewsletters = createAction<Newsletter[]>(Types.SET_NEWSLETTERS);

const Actions = {
    [Types.SET_NEWSLETTERS]: setNewsletters,
};
export type Actions = typeof Actions;

export const getNewsletters = wrapper(NewslettersAPI.getNewsletters, {
    onSuccess: ({ dispatch }, newsletters) => {
        dispatch(setNewsletters(newsletters));
    },
});
