import React from "react";
import { useField, useFormikContext } from "formik";
import AddressInput from "./AddressInput";
import { NzPostAddressDetail } from "../../../redux/actions/api/nzPost";
import useMountEffect from "../../../hooks/useMountEffect";


type Props = {
    name: string;
    label: string;
    disabled?: boolean;
    initialValue?: {
        address: {
            formattedAddress: string;
            address1: string;
            address2: string;
            province: string;
            city: string;
            zip: string;
            country: string;
        }
    };
    placeSelectedCallback?: (place: any) => void;
};

declare global {
    interface Window {
        google: any;
    }
}

window.google = window.google || {};


export default function FormikAddressInput(props: Props) {
    const { name, label, disabled, initialValue, placeSelectedCallback } = props;
    const [, , helpers] = useField(name);
    const { isSubmitting } = useFormikContext();

    const parseAddressReceived = (place: NzPostAddressDetail, formattedAddress: string, province: string) => {
        let address = "";

        if (place.unit_type) {
            address = place.unit_type;
        }

        if (place.unit_value) {
            address = `${address} ${place.unit_value},`;
        }

        if (place.street_number) {
            address = address ? `${address} ${place.street_number}` : `${place.street_number}`;
        }

        if (place.street_alpha) {
            address = `${address}${place.street_alpha}`;
        }

        if (place.street) {
            address = `${address} ${place.street}`;
        }

        if (place.street_type) {
            address = `${address} ${place.street_type}`;
        }

        const addressObject = {
            // nz post API doesn't add country to input string as NZ addresses only, append it
            formattedAddress: `${formattedAddress}, ${place.country}`,
            address1: address,
            address2: place.suburb,
            province: province,
            city: place.city,
            zip: place.postcode,
            country: place.country,
        };

        helpers.setValue(addressObject);
        placeSelectedCallback?.(addressObject);
    };

    const reverseGeocode = (place: NzPostAddressDetail, formattedAddress: string) => {
        const Geocoder = new window.google.maps.Geocoder();
        let province = "";
        Geocoder.geocode({ location: { lat: place.latitude, lng: place.longitude } }, (res: any, status: any) => {
            if (status === "OK") {
                if (res[0]) {
                    res[0].address_components.forEach((addressComponent: any) => {
                        if (addressComponent.types.indexOf("administrative_area_level_1") > -1) {
                            province = addressComponent.long_name;
                        }
                    });
                }
            }
            parseAddressReceived(place, formattedAddress, province);
        });
    };

    const onPlaceSelected = (place: NzPostAddressDetail, formattedAddress: string) => {
        reverseGeocode(place, formattedAddress);
    };

    const onPlaceCleared = () => {
        helpers.setValue(initialValue || null);
    };

    return (
        <AddressInput
            label={label}
            initialValue={initialValue}
            placeSelectedCallback={onPlaceSelected}
            placeClearedCallback={onPlaceCleared}
            disabled={disabled || isSubmitting}
        />
    );
}
