import Dinero from "dinero.js";
import firebase from "firebase/app";
import "firebase/auth";
import { Form, Formik } from "formik";
import { DateTime } from "luxon";
import React, { useRef, useState } from "react";
import { stubArray } from "lodash";
import Input from "src/components/widgets/input/Input";
import useDispatch from "../../../../../../../hooks/useDispatch";
import useMountEffect from "../../../../../../../hooks/useMountEffect";
import useSelector from "../../../../../../../hooks/useSelector";
import { showError, showSuccess } from "../../../../../../../redux/actions/snackbars";
import { getUserAddresses, updateUser } from "../../../../../../../redux/actions/users";
import Address from "../../../../../../../types/Address";
import User, { USER_STATUS } from "../../../../../../../types/User";
import Button from "../../../../../../widgets/button/Button";
import FormikCheckBox from "../../../../../../widgets/checkBox/FormikCheckBox";
import Header from "../../../../../../widgets/header/Header";
import FormikInput from "../../../../../../widgets/input/FormikInput";
import Spinner from "../../../../../../widgets/spinner/Spinner";
import styles from "../../Member.module.scss";
import useAdminState from "../../../../../../../hooks/useAdminState";
import { DialogRef } from "../../../../../../widgets/dialog/Dialog";
import ResetPasswordOnBehalfDialog from "../../../../../../widgets/resetPasswordOnBehalfDialog/ResetPasswordOnBehalfDialog";

type Props = {
    userId: string;
};

export default function UserDetails(props: Props) {
    const { userId } = props;
    const dispatch = useDispatch();
    const dialogRef = useRef<DialogRef>(null);
    const user = useSelector((state) => state.users[userId]);
    const [loading, setLoading] = useState(true);
    const [, isAdmin] = useAdminState();
    const [addresses, setAddresses] = useState<Address[]>([]);
    const [signInMethods, setSignInMethods] = useState<string[]>([]);

    const onGetLoginMethods = async () => {
        setLoading(true);
        const methods = await firebase.auth().fetchSignInMethodsForEmail(user?.email || "");
        setSignInMethods(methods);
        setLoading(false);
    };

    useMountEffect(async () => {
        const newAddresses = await dispatch(getUserAddresses(userId));
        setAddresses(newAddresses);
        await onGetLoginMethods();
        setLoading(false);
    });

    const onSubmit = async (newUser: User) => {
        try {
            await dispatch(updateUser(userId, newUser));
            dispatch(showSuccess("User updated"));
        } catch (err) {
            dispatch(showError(err.message));
        }
    };

    const onSendResetPasswordPress = async () => {
        setLoading(true);
        await firebase.auth().sendPasswordResetEmail(user?.email || "");
        dispatch(showSuccess("Password reset email sent"));
        setLoading(false);
    };

    const onResetOnBehalfPress = () => {
        dialogRef.current?.show();
    };

    const passwordGen = Math.random()
        .toString(36)
        .slice(-8);
    const passwordFormInitialValues = { password: passwordGen, confirm: passwordGen };

    return (
        <div className={styles.form}>
            <Formik<User> onSubmit={onSubmit} initialValues={user}>
                {({ isSubmitting, isValid, dirty, values: { fullAmountDonated, createdAt } }) => (
                    <Form>
                        <Header
                            action={
                                <>
                                    {user.status !== USER_STATUS.DELETED && (
                                        <>
                                            <Button onClick={onSendResetPasswordPress} plain>
                                                Send Reset Password
                                            </Button>
                                            <Button onClick={onResetOnBehalfPress} plain>
                                                Reset Password On Behalf
                                            </Button>
                                        </>
                                    )}
                                    <Button type={"submit"} disabled={isSubmitting || !isValid || !dirty}>
                                        Save
                                    </Button>
                                </>
                            }>
                            Profile
                        </Header>
                        <div className={styles.form_row}>
                            <FormikInput name={"firstName"} label={"First Name"} />
                            <FormikInput name={"lastName"} label={"Last Name"} />
                        </div>
                        <div className={styles.form_row}>
                            <FormikInput name={"email"} label={"Email"} />
                        </div>
                        <div className={styles.merch_container}>
                            <span>
                                <FormikCheckBox
                                    className={styles.inline}
                                    name={"sendMerchandiseOnMilestones"}
                                    text={"This member would like to receive merch once they have reached a milestone."}
                                />
                            </span>
                            {user.sendMerchandiseOnMilestones && (
                                <FormikCheckBox name={"milestoneMerchSent"} text={"Merchandise sent"} />
                            )}
                        </div>
                        <div className={styles.form_row}>
                            <FormikInput
                                name={"stripeCustomerId"}
                                label={"Stripe Customer ID"}
                                placeholder={"Member is linked to this Stripe customer ID"}
                            />
                            <Button
                                href={`https://dashboard.stripe.com/customers/${user.stripeCustomerId}`}
                                className={"stripe_button"}>
                                View on Stripe
                            </Button>
                        </div>
                        <div className={styles.form_row}>
                            <FormikInput
                                name={"stripeSubscriptionId"}
                                label={"Stripe Subscription ID"}
                                placeholder={"Add a subscription ID if you need to manually link a Stripe sub"}
                            />
                            <Button href={`https://dashboard.stripe.com/subscriptions/${user.stripeSubscriptionId}`}>
                                View on Stripe
                            </Button>
                        </div>
                        <div className={styles.form_row}>
                            <FormikInput name={"notes"} label={"Notes"} placeholder={"Add any admin notes here"} />
                        </div>
                        <div className={styles.form_row}>
                            <Input value={signInMethods.join(", ")} disabled label={"Configured sign in methods"} />
                        </div>
                        <div className={styles.form_row}>
                            <p>
                                <strong>Total to date:</strong>{" "}
                                {Dinero({
                                    amount: fullAmountDonated || 0,
                                    currency: "NZD",
                                }).toFormat()}
                            </p>
                            <p>
                                <strong>Member Since:</strong> {DateTime.fromISO(createdAt).toFormat("DD")}
                            </p>
                        </div>
                        {(isSubmitting || loading) && <Spinner overlay />}
                    </Form>
                )}
            </Formik>
            <ResetPasswordOnBehalfDialog
                dialogRef={dialogRef}
                userId={userId}
                title={"Reset password"}
                initialValues={passwordFormInitialValues}
            />
        </div>
    );
}
