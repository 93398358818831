/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 1/02/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import AttachMoneyRounded from "@material-ui/icons/AttachMoneyRounded";
import { FieldInputProps, isNaN } from "formik";
import React from "react";

export type SelectOption = {
    label: string;
    value: string;
};

export type SelectOptions = SelectOption[];

export type InputProps = {
    options?: SelectOption[];
    money?: boolean;
    value: string | number | undefined | null;

    field?: FieldInputProps<string | number>;
} & Omit<TextFieldProps, "name" | "value" | "variant">;

export default function Input(props: InputProps) {
    const { options, money, field, SelectProps, ...otherProps } = props;
    let { value } = props;

    const isSelect = !!options?.length;

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (money) {
            e.target.value = `${Number(e.target.value) * 100}`;
        }
        props.onChange?.(e);
    };

    if (money) {
        value = Number(value) / 100;
        otherProps.InputProps = {
            ...(otherProps.InputProps || {}),
            type: "number",
            startAdornment: (
                <InputAdornment position={"start"}>
                    <AttachMoneyRounded />
                </InputAdornment>
            ),
        };
    }
    value = isNaN(value) ? "" : value ?? "";
    return (
        <TextField
            {...field}
            {...otherProps}
            value={value}
            select={isSelect}
            onChange={onChange}
            SelectProps={{
                fullWidth: true,
                ...SelectProps,
            }}>
            {isSelect &&
                options!.map(({ label, value: optionValue }) => (
                    <MenuItem key={optionValue} value={optionValue}>
                        {label}
                    </MenuItem>
                ))}
        </TextField>
    );
}
