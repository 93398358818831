/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 5/03/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */
import MaterialTable, { Column } from "material-table";
import React, { useRef, useState } from "react";
import useMountEffect from "../../../../../hooks/useMountEffect";
import NewslettersAPI from "../../../../../redux/actions/api/newsletters";
import Newsletter from "../../../../../types/Newsletter";
import Button from "../../../../widgets/button/Button";
import Dialog, { DialogRef } from "../../../../widgets/dialog/Dialog";
import Header from "../../../../widgets/header/Header";
import FormikInput from "../../../../widgets/input/FormikInput";
import styles from "../../../../widgets/pagingTable/PagingTable.module.scss";

const COLUMNS: Column<Newsletter>[] = [
    {
        title: "Mailchimp ID",
        field: "mailchimpAudienceId",
    },
    // {
    //     title: "Preselected",
    //     field: "preselected",
    // },
    {
        title: "Newsletter name",
        field: "description",
    },
    {
        title: "Newsletter description",
        field: "checkboxText",
    },


];

type FormValues = Omit<Newsletter, "id" | "status"> & { id: number | null };

export default function NewsletterSettings() {
    const [loading, setLoading] = useState(true);
    const [newsletters, setNewsletters] = useState<Newsletter[]>([]);
    const [selectedNewsletter, setSelectedNewsletter] = useState<Newsletter | null>(null);
    const dialogRef = useRef<DialogRef>(null);

    useMountEffect(async () => {
        const newNewsletters = await NewslettersAPI.getNewsletters();
        setNewsletters(newNewsletters);
        setLoading(false);
    });

    const onCreateOptionPress = () => {
        dialogRef.current?.show();
    };

    const createUpdateAction = async (values: FormValues) => {
        const options = await NewslettersAPI.createUpdateNewsletters(values);
        setNewsletters(options);
    };

    const deleteAction = async () => {
        if (!selectedNewsletter) {
            return;
        }
        const options = await NewslettersAPI.deleteNewsletter(selectedNewsletter.id);
        setNewsletters(options);
    };

    const onRowPress = (e: any, newsletter: Newsletter) => {
        setSelectedNewsletter(newsletter);
        dialogRef.current?.show();
    };

    const onClose = () => {
        setSelectedNewsletter(null);
    };

    return (
        <>
            <Header action={<Button onClick={onCreateOptionPress}>Create</Button>}>Newsletters</Header>
            <MaterialTable
                columns={COLUMNS}
                data={newsletters}
                isLoading={loading}
                components={{
                    Container: (ContainerProps: any) => <div {...ContainerProps} className={styles.paging_table} />,
                }}
                onRowClick={onRowPress}
                options={{
                    toolbar: false,
                    draggable: false,
                    paging: false,
                    search: false,
                }}
            />

            <Dialog<FormValues>
                title={selectedNewsletter ? "Update Newsletter" : "Create Newsletter"}
                updating={!!selectedNewsletter}
                dialogRef={dialogRef}
                onClose={onClose}
                createAction={createUpdateAction}
                updateAction={createUpdateAction}
                deleteAction={selectedNewsletter ? deleteAction : null}
                initialValues={
                    selectedNewsletter || {
                        id: null,
                        mailchimpAudienceId: "",
                        preselected: false,
                        description: "",
                        checkboxText: "",
                    }
                }>
                <FormikInput name={"mailchimpAudienceId"} label={"Mailchimp ID"} />
                <FormikInput name={"description"} label={"Newsletter name"} />
                <FormikInput name={"checkboxText"} label={"Newsletter description"} />

            </Dialog>
        </>
    );
}
