import MuiButton, { ButtonProps as MuiButtonProps } from "@material-ui/core/Button";
import classNames from "classnames";
import React, { Ref } from "react";
import Spinner from "../spinner/Spinner";
import styles from "./Button.module.scss";

export type ButtonProps2 = {
    plain?: boolean;
    green?: boolean;
    blue?: boolean;
    yellow?: boolean;
    bold?: boolean;
    children?: string;
    rootClassName?: string;
    labelClassName?: string;
    loading?: boolean;
    component?: string;
} & MuiButtonProps;

export type ButtonProps = {
    loading?: boolean;
    plain?: boolean;
    red?: boolean;
    redPlain?: boolean;
    yellow?: boolean;
    secondary?: boolean;
    small?: boolean;
} & MuiButtonProps;

function Button(props: ButtonProps, ref: Ref<HTMLButtonElement>) {
    const { children, loading, plain, red, redPlain, yellow, secondary, small, endIcon, variant, disabled, ...otherProps } = props;

    const isDisabled = disabled || loading;
    const className = classNames(
        styles.button,
        {
            [styles.green]: !plain,
            [styles.red]: red,
            [styles.red_plain]: redPlain,
            [styles.yellow]: yellow,
            [styles.plain]: plain,
            [styles.disabled]: isDisabled,
        },
        props.className,
    );

    let size: "small" | "medium" | "large" | undefined;
    if (small) {
        size = "small";
    }

    return (
        <MuiButton {...otherProps} disabled={isDisabled} className={className} size={size} variant={variant || "text"} buttonRef={ref}>
            {loading ? <Spinner small white={!plain} /> : children}
        </MuiButton>
    );
}

export default React.forwardRef(Button);
