/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 12/03/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import { Table, TableCell, TableRow } from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import useAsyncEffect from "../../../../../../../hooks/useAsyncEffect";
import useDispatch from "../../../../../../../hooks/useDispatch";
import useSelector from "../../../../../../../hooks/useSelector";
import { showSuccess } from "../../../../../../../redux/actions/snackbars";
import {
    cancelUserSubscription,
    createUserSubscription,
    getUserSubscription,
    updateUserSubscription,
} from "../../../../../../../redux/actions/subscriptions";
import Subscription, { SUBSCRIPTION_FREQUENCY, SUBSCRIPTION_STATUS } from "../../../../../../../types/Subscription";
import Button from "../../../../../../widgets/button/Button";
import FormikDateInput from "../../../../../../widgets/dateInput/FormikDateInput";
import Header from "../../../../../../widgets/header/Header";
import FormikInput from "../../../../../../widgets/input/FormikInput";
import Spinner from "../../../../../../widgets/spinner/Spinner";
import styles from "../../Member.module.scss";

type Props = {
    userId: string;
};

export default function UserSubscriptionDetails(props: Props) {
    const { userId } = props;
    const dispatch = useDispatch();
    const subscription = useSelector((state) => state.subscriptions[userId]);
    const [loading, setLoading] = useState(true);

    useAsyncEffect(async () => {
        await dispatch(getUserSubscription(userId));
        setLoading(false);
    }, []);

    const onSubmitUpdateSub = async (newSubscription: Subscription) => {
        await dispatch(updateUserSubscription(userId, newSubscription));
        dispatch(showSuccess("User subscription updated"));
    };

    const onSubmitCreateSub = async (values: any) => {
        console.log("values", values);
        await dispatch(createUserSubscription(userId, values));
        dispatch(showSuccess("User subscription created"));
    };

    const onCancelSubscriptionPress = async () => {
        setLoading(true);
        await dispatch(cancelUserSubscription(userId));
        setLoading(false);
    };

    const renderCreateSubscription = () => (
        <Formik<Pick<Subscription, "amount" | "interval" | "firstChargeAt">>
            onSubmit={onSubmitCreateSub}
            enableReinitialize
            validationSchema={Yup.object({
                amount: Yup.number().positive("Amount required"),
                interval: Yup.string().required("Interval required"),
                firstChargeAt: Yup.string().required("Start date required"),
            })}
            initialValues={{ amount: 0, interval: SUBSCRIPTION_FREQUENCY.MONTH, firstChargeAt: "" }}>
            {({ isSubmitting, isValid, dirty }) => (
                <Form>
                    <Header
                        action={
                            <Button type={"submit"} disabled={isSubmitting || !isValid || !dirty}>
                                Create subscription
                            </Button>
                        }>
                        Subscription
                    </Header>
                    {loading ? (
                        <Spinner hCenter />
                    ) : (
                        <div style={{ marginTop: "27px" }}>
                            <span>The user does not have an active subscription, create one below</span>
                            <div className={styles.form_row_full}>
                                <FormikInput
                                    name={"interval"}
                                    label={"Interval"}
                                    options={[
                                        {
                                            label: "Monthly",
                                            value: "MONTH",
                                        },
                                        {
                                            label: "Annually",
                                            value: "YEAR",
                                        },
                                    ]}
                                />
                                <FormikInput money name={"amount"} label={"Amount"} />
                                <FormikDateInput name={"firstChargeAt"} label={"Subscription start date"} disablePast />
                            </div>
                            {isSubmitting && <Spinner overlay />}
                        </div>
                    )}
                </Form>
            )}
        </Formik>
    );

    const renderUpdateSubscription = () => (
        <Formik<Subscription> onSubmit={onSubmitUpdateSub} enableReinitialize initialValues={subscription}>
            {({ isSubmitting, isValid, dirty }) => (
                <Form>
                    <Header
                        action={
                            subscription?.status === SUBSCRIPTION_STATUS.ACTIVE && (
                                <Button type={"submit"} disabled={isSubmitting || !isValid || !dirty}>
                                    Save
                                </Button>
                            )
                        }>
                        Subscription
                    </Header>
                    {loading ? (
                        <Spinner hCenter />
                    ) : (
                        <div style={{ marginTop: "27px" }}>
                            {subscription.status === SUBSCRIPTION_STATUS.CANCELLED ? (
                                <span>The users subscription has been cancelled.</span>
                            ) : (
                                <>
                                    {subscription.subscriptionURL ? (
                                        <div>
                                            <div className={`${styles.warning} ${styles.form_row_full}`}>
                                                This member&apos;s subscription is now managed in Stripe.&nbsp;
                                                <a href={subscription.subscriptionURL} target={"_blank"} rel={"noopener noreferrer"}>
                                                    Click here to view in Stripe
                                                </a>
                                            </div>
                                            <div className={styles.form_row_full}>
                                                <Table>
                                                    <TableRow>
                                                        <TableCell>Amount: ${subscription.amount / 100}</TableCell>
                                                        <TableCell>Frequency: {subscription.interval}</TableCell>
                                                    </TableRow>
                                                </Table>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className={styles.form_row_full}>
                                            <FormikInput
                                                name={"interval"}
                                                label={"Interval"}
                                                options={[
                                                    {
                                                        label: "Monthly",
                                                        value: "MONTH",
                                                    },
                                                    {
                                                        label: "Annually",
                                                        value: "YEAR",
                                                    },
                                                ]}
                                            />
                                            <FormikInput money name={"amount"} label={"Amount"} />
                                            <FormikDateInput name={"nextChargeAt"} label={"Next Charge At"} disablePast />
                                        </div>
                                    )}
                                    <Button redPlain loading={loading} onClick={onCancelSubscriptionPress}>
                                        Cancel Subscription
                                    </Button>
                                </>
                            )}
                            {isSubmitting && <Spinner overlay />}
                        </div>
                    )}
                </Form>
            )}
        </Formik>
    );

    return <div className={styles.form}>{subscription ? renderUpdateSubscription() : renderCreateSubscription()}</div>;
}
