/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 12/03/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import User from "../../types/User";
import UsersAPI from "./api/users";
import createAction from "./helpers/createAction";
import wrapper from "./helpers/wrapper";

export enum Types {
    SET_USER = "users:SET_USER",
}

export const setUser = createAction<User>(Types.SET_USER);

export const getUser = wrapper(UsersAPI.getUser, { action: setUser });
export const updateUser = wrapper(UsersAPI.updateUser, { action: setUser });
export const getUserTotalToDate = wrapper(UsersAPI.getUserTotalToDate);
export const getUserAddresses = wrapper(UsersAPI.getUserAddresses);

export const createUpdateUserAddress = wrapper(UsersAPI.createUpdateUserAddress);
export const createUserAdmin = wrapper(UsersAPI.createUserAdmin);

export const Actions = {
    [Types.SET_USER]: setUser,
};
export type Actions = typeof Actions;
