import User from "../../../types/User";
import get from "./util/get";
import post from "./util/post";

function getUser(): Promise<User> {
    return get("/v1/me");
}

function updateUserPWordAdmin(userId: String, pword: String) {
    return post("/v1/admin/users/pword/reset", { userId: userId, pword: pword });
}

const UsersAPI = {
    getUser: getUser,
    updateUserPWordAdmin: updateUserPWordAdmin,
};

export default UsersAPI;
