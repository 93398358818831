/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 17/03/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import Dinero from "dinero.js";
import { DateTime } from "luxon";
import { Column } from "material-table";
import React, { useState } from "react";
import useSelector from "src/hooks/useSelector";
import TransactionsAPI from "../../../../../../../redux/actions/api/transactions";
import Transaction from "../../../../../../../types/Transaction";
import Header from "../../../../../../widgets/header/Header";
import PagingTable from "../../../../../../widgets/pagingTable/PagingTable";
import styles from "./UserTransactions.module.scss";

type Props = {
    userId: string;
};

const COLUMNS: Column<Transaction>[] = [
    {
        title: "Id",
        field: "id",
    },
    {
        title: "Date",
        field: "created",
        render: ({ created }) => (created ? DateTime.fromISO(created).toFormat("dd/MM/yy") : "-"),
    },
    {
        title: "Amount",
        field: "amount",
        render: ({ amount, currency }) => Dinero({ amount: amount || 0, currency: currency || "NZD" }).toFormat(),
    },
    {
        title: "Description",
        field: "description",
    },
    {
        title: "Type",
        field: "metadata.type",
    },
    {
        title: "Status",
        field: "status",
        render: ({ status }) => (status || "").toUpperCase(),
    },
];

type Filter = NonNullable<Parameters<typeof TransactionsAPI.getTransactions>[0]>;

export default function UserTransactions(props: Props) {
    const { userId } = props;
    const user = useSelector((state) => state.users[userId]);
    const [filter, setFilter] = useState<Filter>({
        user: userId,
        limit: 5,
        order: null,
    });

    // const onConfirmDateRangePress = (dateRange: DateRange) => {
    //     setFilter((state) => ({
    //         ...state,
    //         from: dateRange.from,
    //         to: dateRange.to,
    //     }));
    // };

    return (
        <div className={styles.user_transactions}>
            <Header>Transactions</Header>
            <PagingTable columns={COLUMNS} filter={filter} getData={TransactionsAPI.getTransactions} setFilter={setFilter} />
        </div>
    );
}
