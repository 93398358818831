/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 11/03/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import React, { useState } from "react";
import usePrevious from "../../../hooks/usePrevious";
import styles from "./DateRangeInput.module.scss";
import Button from "../button/Button";
import DateInput from "../dateInput/DateInput";

export type DateRange = {
    from: string | null;
    to: string | null;
};

type Props = {
    value?: DateRange;
    onConfirm: (dateRange: DateRange) => void;
};

export default function DateRangeInput(props: Props) {
    const { onConfirm } = props;

    const [dateRange, setDateRange] = useState<DateRange>({
        from: null,
        to: null,
    });

    const previousDateRange = usePrevious(dateRange);
    const hasChanged = previousDateRange.from !== dateRange.from || previousDateRange.to !== dateRange.to;

    const onFromDateChange = (date: string) => {
        setDateRange((state) => ({
            ...state,
            from: date,
            // to: null,
        }));
    };
    const onToDateChange = (date: string) => {
        setDateRange((state) => ({
            ...state,
            to: date,
        }));
    };

    const value = props.value || dateRange;

    const onConfirmDateRangePress = () => {
        onConfirm(dateRange);
    };

    const onResetDateRangePress = () => {
        const newDateRange = {
            from: null,
            to: null,
        };
        setDateRange(newDateRange);
        onConfirm(newDateRange);
    };

    const isConfirmDisabled = !hasChanged || !value.from || !value.to;

    return (
        <div className={styles.filter}>
            <DateInput value={value.from} onChange={onFromDateChange} label={"From"} disableFuture />
            <DateInput
                value={value.to}
                onChange={onToDateChange}
                label={"To"}
                disabled={!value.from}
                minDate={value.from}
                disableFuture
            />
            <Button
                className={styles.confirm_date_range}
                plain={isConfirmDisabled}
                disabled={isConfirmDisabled}
                onClick={onConfirmDateRangePress}>
                Confirm
            </Button>
            <Button className={styles.reset_date_range} disabled={!value.from && !value.to} plain onClick={onResetDateRangePress}>
                Reset
            </Button>
        </div>
    );
}
