import { Form, Formik } from "formik";
import React, { useState } from "react";
import useDispatch from "../../../../../../../hooks/useDispatch";
import useMountEffect from "../../../../../../../hooks/useMountEffect";
import useSelector from "../../../../../../../hooks/useSelector";
import { showError, showSuccess } from "../../../../../../../redux/actions/snackbars";
import { createUpdateUserAddress, getUserAddresses } from "../../../../../../../redux/actions/users";
import Address from "../../../../../../../types/Address";
import Button from "../../../../../../widgets/button/Button";
import Header from "../../../../../../widgets/header/Header";
import Spinner from "../../../../../../widgets/spinner/Spinner";
import styles from "../../Member.module.scss";
import FormikAddressInput from "../../../../../../widgets/input/FormikAddressInput";

type Props = {
    userId: string;
};

type AddressFormValues = Pick<Address, "address1" | "address2" | "province" | "city" | "zip" | "country" | "formattedAddress">;
type FormValues = { address: AddressFormValues }

export default function UserAddresses(props: Props) {
    const { userId } = props;
    const dispatch = useDispatch();
    const user = useSelector((state) => state.users[userId]);
    const [loading, setLoading] = useState(true);
    const [addresses, setAddresses] = useState<Address[]>([]);
    const [defaultAddress, setDefaultAddress] = useState<Address>();
    useMountEffect(async () => {
        const newAddresses = await dispatch(getUserAddresses(userId));
        setAddresses(newAddresses);
        setDefaultAddress(newAddresses.find((a) => a.default));
        setLoading(false);
    });
    const onSubmit = async (values: any) => {
        try {
            await dispatch(createUpdateUserAddress(userId, { ...values.address, default: true }));
            dispatch(showSuccess("User address updated"));

        } catch (err) {
            dispatch(showError(err.message));
        }
    };

    return (
        <div className={styles.form}>
            {loading ? <Spinner overlay /> :

                <Formik<FormValues> onSubmit={onSubmit}
                                    isInitialValid={false}
                                    initialValues={{
                                        address: {
                                            formattedAddress: defaultAddress?.formattedAddress || "",
                                            address1: defaultAddress?.address1 || "",
                                            address2: defaultAddress?.address2 || "",
                                            province: defaultAddress?.province || "",
                                            city: defaultAddress?.city || "",
                                            zip: defaultAddress?.zip || "",
                                            country: defaultAddress?.country || "",
                                        }
                                    }}>
                    {({ isSubmitting, isValid, dirty }) => (
                        <Form>

                            <div>
                                <Header
                                    action={
                                        <Button type={"submit"} disabled={isSubmitting || !isValid || !dirty}>
                                            Save
                                        </Button>
                                    }
                                >Addresses</Header>
                                <div style={{ marginTop: "27px" }}>
                                    {addresses.length > 0 ? (
                                        <div>
                                            <FormikAddressInput
                                                name={"address"}
                                                disabled={isSubmitting}
                                                initialValue={{
                                                    address: {
                                                        formattedAddress: defaultAddress?.formattedAddress || "",
                                                        address1: defaultAddress?.address1 || "",
                                                        address2: defaultAddress?.address2 || "",
                                                        province: defaultAddress?.province || "",
                                                        city: defaultAddress?.city || "",
                                                        zip: defaultAddress?.zip || "",
                                                        country: defaultAddress?.country || ""
                                                    }
                                                }}
                                                label={"Edit user address"}
                                            />
                                        </div>
                                    ) : (
                                        <>
                                            <div style={{ marginBottom: 16 }}>This user does not have an address saved.
                                            </div>
                                            <FormikAddressInput name={"address"} label={"Add user address"} />
                                        </>
                                    )}
                                </div>
                            </div>

                        </Form>
                    )}
                </Formik>
            }
        </div>
    );
}
