/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 17/03/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import SubscriptionOption from "../../../types/SubscriptionOption";
import del from "./util/del";
import get from "./util/get";
import post from "./util/post";

function getSubscriptionOptions(): Promise<SubscriptionOption[]> {
    return get(`/v1/subscription_options`);
}

function createOrUpdateSubscriptionOptions(
    option: Omit<SubscriptionOption, "id" | "status"> & { id: number | null },
): Promise<SubscriptionOption[]> {
    return post(`/v1/admin/subscription_options`, option);
}

function deleteSubscriptionOptions(subscriptionOptionId: number): Promise<SubscriptionOption[]> {
    return del(`/v1/admin/subscription_options/${subscriptionOptionId}`);
}

const SubscriptionOptionsAPI = {
    getSubscriptionOptions: getSubscriptionOptions,
    createOrUpdateSubscriptionOptions: createOrUpdateSubscriptionOptions,
    deleteSubscriptionOption: deleteSubscriptionOptions,
};

export default SubscriptionOptionsAPI;
