/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 12/02/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import User from "../../types/User";
import { Actions, Types } from "../actions/user";

const initialState: User | null = null;

export type ActionTypes = ReturnType<Actions[keyof Actions]>;
export type ActionValueTypes<T extends Types> = ReturnType<Actions[T]>["payload"];

export default function user(state = initialState, action: ActionTypes) {
    switch (action.type) {
        case Types.SET_USER: {
            return (action.payload as ActionValueTypes<Types.SET_USER>) || null;
        }
        default: {
            return state;
        }
    }
}
