/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 14/02/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import { DependencyList, useEffect as useReactEffect } from "react";

export default function useAsyncEffect(effect: () => void, deps?: DependencyList, onError?: (err: any) => void) {
    useReactEffect(() => {
        const asyncFunc = async () => {
            await effect();
        };
        asyncFunc().catch(onError);
    }, deps);
}
