/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 11/03/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import React, { useState } from "react";
import usePrevious from "../../../hooks/usePrevious";
import Button from "../button/Button";
import DateInput from "../dateInput/DateInput";
import Input, { SelectOptions } from "../input/Input";
import styles from "./DateRangeFilter.module.scss";

export type TDateRangeFilter = {
    type: string;
    from: string | null;
    to: string | null;
};

type Props = {
    value?: TDateRangeFilter;
    onConfirm: (dateRange: TDateRangeFilter) => void;
    optionLabel?: string;
    options: SelectOptions;
};

export default function DateRangeFilter(props: Props) {
    const { onConfirm, optionLabel, options } = props;

    const [filter, setFilter] = useState<TDateRangeFilter>({
        type: options[0].value || "",
        from: null,
        to: null,
    });

    const prevFilter = usePrevious(filter);
    const hasChanged = prevFilter.type !== filter.type || prevFilter.from !== filter.from || prevFilter.to !== filter.to;

    const onOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const type = e.target.value;
        setFilter((state) => ({
            ...state,
            type: type,
        }));
    };

    const onFromDateChange = (date: string) => {
        setFilter((state) => ({
            ...state,
            from: date,
        }));
    };
    const onToDateChange = (date: string) => {
        setFilter((state) => ({
            ...state,
            to: date,
        }));
    };

    const value = props.value || filter;

    const onConfirmDateRangePress = () => {
        onConfirm(filter);
    };

    const onResetDateRangePress = () => {
        const newDateRangeFilter = {
            type: filter.type,
            from: null,
            to: null,
        };
        setFilter(newDateRangeFilter);
        onConfirm(newDateRangeFilter);
    };

    const isConfirmDisabled = !hasChanged || !value.from || !value.to;

    return (
        <div className={styles.filter}>
            <Input label={optionLabel} value={value.type} onChange={onOptionChange} options={options} />
            <DateInput value={value.from} onChange={onFromDateChange} label={"From"} disableFuture />
            <DateInput
                value={value.to}
                onChange={onToDateChange}
                label={"To"}
                disabled={!value.from}
                minDate={value.from}
            />
            <Button
                className={styles.confirm_date_range}
                plain={isConfirmDisabled}
                disabled={isConfirmDisabled}
                onClick={onConfirmDateRangePress}>
                Confirm
            </Button>
            <Button className={styles.reset_date_range} disabled={!value.from && !value.to} plain onClick={onResetDateRangePress}>
                Reset
            </Button>
        </div>
    );
}
