/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 8/11/19.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import Nullable from "../types/Nullable";
import User, { USER_ROLE } from "../types/User";
import useSelector from "./useSelector";

export default function useAdminState(): [boolean, boolean, Nullable<User>] {
    const user = useSelector((state) => state.user);
    const isAdmin = process.env.NODE_ENV === "development" || user?.role === USER_ROLE.ADMIN;
    console.log(process.env);
    return [!!user?.id, isAdmin, user];
}
