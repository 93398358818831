/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 11/03/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import React, { useState } from "react";
import usePrevious from "../../../hooks/usePrevious";
import Button from "../button/Button";
import Input, { SelectOptions } from "../input/Input";
import styles from "./DateRangeInput.module.scss";

export type Search = {
    type: string;
    text: string;
};

type Props = {
    value?: Search;
    options: SelectOptions;
    onConfirm: (search: Search) => void;
};

export default function SearchInput(props: Props) {
    const { options, onConfirm } = props;

    const [search, setSearch] = useState<Search>({
        type: options[0]?.value || "",
        text: "",
    });

    const previousSearch = usePrevious(search);
    const hasChanged = previousSearch.type !== search.type || previousSearch.text !== search.text;

    const onSearchTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const searchType = e.target.value;
        setSearch({
            text: search.text,
            type: searchType,
        });
    };

    const onSearchTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const searchText = e.target.value;
        setSearch({
            type: search.type,
            text: searchText,
        });
    };

    const value = props.value || search;
    const onConfirmDateRangePress = () => {
        onConfirm(search);
    };

    const onResetDateRangePress = () => {
        const newSearch = {
            type: search.type,
            text: "",
        };
        setSearch(newSearch);
        onConfirm(newSearch);
    };

    const isConfirmDisabled = !hasChanged || !value.text;

    return (
        <div className={styles.search}>
            <Input value={search.type} onChange={onSearchTypeChange} options={options} />
            <Input value={search.text} onChange={onSearchTextChange} placeholder={"Search"} />
            <Button
                className={styles.confirm_date_range}
                plain={isConfirmDisabled}
                disabled={isConfirmDisabled}
                onClick={onConfirmDateRangePress}>
                Confirm
            </Button>
            <Button
                className={styles.reset_date_range}
                disabled={!value.text && !previousSearch.text}
                plain
                onClick={onResetDateRangePress}>
                Reset
            </Button>
        </div>
    );
}
