/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 17/03/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

export enum SUBSCRIPTION_OPTION_TYPE {
    SUBSCRIPTION = "SUBSCRIPTION",
    PAY_WHAT_YOU_WANT = "PAY_WHAT_YOU_WANT",
}

export enum SUBSCRIPTION_OPTION_PERIOD {
    MONTHLY = "MONTHLY",
    YEARLY = "YEARLY",
    ONE_OFF = "ONE_OFF",
}

export enum SUBSCRIPTION_OPTION_STATUS {
    ACTIVE = "ACTIVE",
    DELETED = "DELETED",
}

export default interface SubscriptionOption {
    id: number;
    type: SUBSCRIPTION_OPTION_TYPE;
    period: SUBSCRIPTION_OPTION_PERIOD;
    status: SUBSCRIPTION_OPTION_STATUS;
    preselected: boolean;
    amount: number;
}
