/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 5/03/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import {Currency} from "dinero.js";
import User from "./User";

export enum SUBSCRIPTION_FREQUENCY {
    ONE_TIME = "ONE_TIME",
    WEEK = "WEEK",
    MONTH = "MONTH",
    YEAR = "YEAR",
}

export enum SUBSCRIPTION_STATUS {
    ACTIVE = "ACTIVE",
    CANCELLED = "CANCELLED",
}

export default interface Subscription {
    id: number;
    user: User;
    amount: number;
    currency: Currency;
    interval: SUBSCRIPTION_FREQUENCY;
    firstChargeAt: string;
    lastChargeAt: string;
    termStartedAt: string;
    nextChargeAt: string;
    chargeFailedAt: string;
    chargeAttempts: number;
    createdAt: string;
    updatedAt: string;
    endedAt: string;
    status: SUBSCRIPTION_STATUS;
    // This only exists on Stripe subscriptions (v2 API)
    subscriptionURL: string;
}
