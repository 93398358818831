/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 12/03/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import React from "react";
import { RouteComponentProps } from "react-router-dom";
import useAsyncEffect from "../../../../../hooks/useAsyncEffect";
import useDispatch from "../../../../../hooks/useDispatch";
import useSelector from "../../../../../hooks/useSelector";
import { getUser } from "../../../../../redux/actions/users";
import formatName from "../../../../../util/formatName";
import Header from "../../../../widgets/header/Header";
import Spinner from "../../../../widgets/spinner/Spinner";
import UserDetails from "./components/userDetails/UserDetails";
import UserSubscriptionDetails from "./components/userSubscriptionDetails/UserSubscriptionDetails";
import UserTransactions from "./components/userTransactions/UserTransactions";
import styles from "./Member.module.scss";
import UserAddresses from "./components/userAddresses/UserAddresses";

type Props = {};

export default function Member(props: RouteComponentProps<{ userId: string }>) {
    const { match } = props;
    const { params } = match;
    const { userId } = params;

    const dispatch = useDispatch();
    const user = useSelector((state) => state.users[userId]);

    useAsyncEffect(async () => {
        await dispatch(getUser(userId));
    }, []);

    return (
        <div className={styles.member}>
            <Header large>{formatName(user)}</Header>
            {user ? (
                <>
                    <UserDetails userId={userId} />
                    <UserAddresses userId={userId} />
                    <UserSubscriptionDetails userId={userId} />
                    <UserTransactions userId={userId} />
                </>
            ) : (
                <Spinner hCenter />
            )}
        </div>
    );
}
