/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 5/03/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import Card from "@material-ui/core/Card";
import firebase from "firebase/app";
import "firebase/auth";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import TheSpinoffLogo from "../../../resources/images/js/TheSpinoffLogo";
import Button from "../../widgets/button/Button";
import Header from "../../widgets/header/Header";
import FormikInput from "../../widgets/input/FormikInput";
import styles from "./Login.module.scss";

type FormValues = {
    email: string;
    password: string;
};

export const ValidationSchema = Yup.object<FormValues>({
    email: Yup.string()
        .email("Invalid email")
        .required("Required"),
    password: Yup.string().required("Required"),
});

export default function Login() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const onSubmit = async ({ email, password }: FormValues) => {
        setLoading(true);
        setError(null);
        try {
            await firebase.auth().signInWithEmailAndPassword(email, password);
        } catch (err) {
            setError(err.message);
            setLoading(false);
        }
    };

    return (
        <div className={styles.login}>
            <div className={styles.inner}>
                <TheSpinoffLogo className={styles.logo} />
                <Card className={styles.card}>
                    <Header>Login</Header>
                    <Formik
                        enableReinitialize
                        onSubmit={onSubmit}
                        validationSchema={ValidationSchema}
                        initialValues={{
                            email: "",
                            password: "",
                        }}>
                        {({ dirty, isValid }) => (
                            <Form className={styles.form}>
                                <FormikInput name={"email"} label={"Email"} placeholder={"admin@thespinoff.co.nz"} />
                                <FormikInput name={"password"} label={"Password"} type={"password"} placeholder={"••••••••"} />
                                {error && <span>{error}</span>}
                                <Button type={"submit"} disabled={!dirty || !isValid} loading={loading}>
                                    Login
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </Card>
            </div>
        </div>
    );
}
