/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 1/02/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import { TextFieldProps } from "@material-ui/core/TextField";
import React from "react";
import useFormikField from "../../../hooks/useFormikField";
import Input from "./Input";

export type SelectOption = {
    label: string;
    value: string;
};

export type InputProps = {
    name: string;
    options?: SelectOption[];
    money?: boolean;
} & Omit<TextFieldProps, "name" | "value" | "error" | "variant">;

export default function FormikInput(props: InputProps) {
    const { name, options, ...otherProps } = props;

    const { value, disabled, showError, helperText, field } = useFormikField(props);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        field.onChange(e);
        props.onChange?.(e);
    };

    return (
        <Input
            {...otherProps}
            options={options}
            field={field}
            value={value}
            onChange={onChange}
            disabled={disabled}
            error={showError}
            helperText={helperText}
        />
    );
}
