/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 3/03/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */
import classNames from "classnames";
import React, { PropsWithChildren, ReactNode } from "react";
import styles from "./Header.module.scss";

type HeaderProps = PropsWithChildren<{
    action?: ReactNode;
    large?: boolean;
}>;

export default function Header(props: HeaderProps) {
    const { children, action, large } = props;

    const className = classNames(styles.header, {
        [styles.large]: large,
    });

    return (
        <div className={className}>
            <div className={styles.content}>
                <div className={styles.text}>
                    <h3>{children}</h3>
                </div>
                <div className={styles.action}>{action}</div>
            </div>
        </div>
    );
}
