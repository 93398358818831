import get from "./util/get";

const nzPostParcelAddressAPI = "https://api.nzpost.co.nz/parceladdress/2.0/domestic/addresses";

export interface NzPostAddressSuggestion {
    full_address: string;
    address_id: string;
    dpid: string;
}

export interface NzPostAddressDetail {
    unit_type: string;
    unit_value: string;
    street_number: number;
    street_alpha: string;
    street: string;
    street_type: string;
    suburb: string;
    city: string;
    is_rural_delivery: boolean;
    gated_community_flag: boolean;
    postcode: string;
    longitude: number;
    latitude: number;
    run_number: string;
    depot_name: string;
    country: string;
    dpid: number;
    address_id: number;
}

async function getToken() {
    const { access_token } = await get("/v1/nz-post/token");
    sessionStorage.setItem("nzPostToken", access_token);
}

async function getPredictions(query: string, callback: (suggestions: NzPostAddressSuggestion[]) => void) {
    if (!sessionStorage.getItem("nzPostToken")) {
        await getToken();
    }

    const res: { success: boolean; addresses: NzPostAddressSuggestion[]; message_id: string } = await get("", {
        baseURL: nzPostParcelAddressAPI,
        params: {
            q: query,
            count: 5,
            client_id: process.env.REACT_APP_NZ_POST_CLIENT_ID,
        },
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem("nzPostToken")}`,
        },
    });

    if (res.success) {
        callback(res.addresses);
    }
}

async function getAddressDetail(addressId: string): Promise<NzPostAddressDetail | null> {
    if (!sessionStorage.getItem("nzPostToken")) {
        await getToken();
    }

    const res: { success: boolean; address: NzPostAddressDetail; message_id: string } = await get(`/${addressId}`, {
        baseURL: nzPostParcelAddressAPI,
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem("nzPostToken")}`,
        },
    });

    if (res.success) {
        return res.address;
    }

    return null;
}

const nzPostAPI = {
    getPredictions: getPredictions,
    getAddressDetail: getAddressDetail,
    getToken: getToken,
};

export default nzPostAPI;
