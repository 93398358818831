/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 4/03/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */
import Newsletter from "../../../types/Newsletter";
import del from "./util/del";
import get from "./util/get";
import post from "./util/post";

function getNewsletters(): Promise<Newsletter[]> {
    return get(`/v1/newsletters`);
}

function createUpdateNewsletters(newsletter: Omit<Newsletter, "id" | "status"> & { id: number | null }): Promise<Newsletter[]> {
    return post(`/v1/admin/newsletters`, newsletter);
}

function deleteNewsletter(id: number): Promise<Newsletter[]> {
    return del(`/v1/admin/newsletters/${id}`);
}

const NewslettersAPI = {
    getNewsletters: getNewsletters,
    createUpdateNewsletters: createUpdateNewsletters,
    deleteNewsletter: deleteNewsletter,
};

export default NewslettersAPI;
