import React, { useEffect, useMemo, useRef, useState } from "react";
import _throttle from "lodash/throttle";
import classNames from "classnames";
import uuid from "uuid";
import nzPostAPI, { NzPostAddressDetail, NzPostAddressSuggestion } from "../../../redux/actions/api/nzPost";
import styles from "./AddressInput.module.scss";

type Props = {
    initialValue?: {
        address: {
            formattedAddress: string;
            address1: string;
            address2: string;
            province: string;
            city: string;
            zip: string;
            country: string;
        }
    };
    label: string;
    placeSelectedCallback: (place: NzPostAddressDetail, formattedAddress: string) => void;
    placeClearedCallback: () => void;

    disabled?: boolean;
    placeholder?: string;
};

export default function AddressInput(props: Props) {
    const { initialValue, label, placeSelectedCallback, placeClearedCallback, disabled, placeholder } = props;
    const inputRef = useRef(null);
    const [inputValue, setInputValue] = useState("");
    const [suggestions, setSuggestions] = useState<NzPostAddressSuggestion[]>([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const labelClassName = classNames(styles.label, { [styles.label_disabled]: disabled });
    const inputClassName = classNames(styles.input, { [styles.input_disabled]: disabled });

    const initialAddressString = initialValue?.address.formattedAddress;
    useEffect(() => {
        if (initialAddressString) {
            setInputValue(initialAddressString);
        }
    }, [initialAddressString]);

    const onChange = (e: any) => {
        setShowSuggestions(true);
        if (!e.target.value?.length) {
            placeClearedCallback();
        }
        setInputValue(e.target.value);
    };

    const onPlaceSelected = async (addressId: string, addressString: string) => {
        const place = await nzPostAPI.getAddressDetail(addressId);

        if (place) {
            setInputValue(addressString);
            setShowSuggestions(false);
            placeSelectedCallback(place, addressString);
        }
    };

    const fetch = useMemo(
        () =>
            _throttle((request: { input: string }, callback: (results?: NzPostAddressSuggestion[]) => void) => {
                nzPostAPI.getPredictions(request.input, callback);
            }, 200),
        [],
    );

    useEffect(() => {
        let active = true;

        // nz post api requires min 4 chars for a valid query
        if (inputValue === "" || inputValue.length < 4) {
            return undefined;
        }

        fetch({ input: inputValue }, (results?: NzPostAddressSuggestion[]) => {
            if (active) {
                let newOptions = [] as NzPostAddressSuggestion[];

                if (results) {
                    newOptions = [...results];
                }

                setSuggestions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [inputValue, fetch]);

    const renderSuggestions = (s: NzPostAddressSuggestion[]) => (
        <div className={styles.suggestions_container}>
            {s.map((item) => (
                <div
                    key={uuid()}
                    className={styles.suggestion}
                    onClick={() => onPlaceSelected(item.address_id, item.full_address)}>
                    {item.full_address}
                </div>
            ))}
        </div>
    );

    return (
        <div className={styles.input_wrapper}>
            {label && <label className={labelClassName}>{label}</label>}
            <div className={styles.autocomplete_wrapper}>
                <input
                    ref={inputRef}
                    value={inputValue}
                    className={inputClassName}
                    onChange={onChange}
                    onFocus={() => setShowSuggestions(true)}
                    onBlur={() => {
                        setTimeout(() => setShowSuggestions(false), 250);
                    }}
                    disabled={disabled}
                    placeholder={placeholder}
                />
                {suggestions && showSuggestions && renderSuggestions(suggestions)}
            </div>
        </div>
    );
}
