/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 14/02/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import Fade from "@material-ui/core/Fade";
import IconButton from "@material-ui/core/IconButton";
import MatSnackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import CheckCircleIcon from "@material-ui/icons/CheckCircleRounded";
import CloseIcon from "@material-ui/icons/CloseRounded";
import ErrorIcon from "@material-ui/icons/ErrorRounded";
import InfoIcon from "@material-ui/icons/InfoRounded";
import WarningIcon from "@material-ui/icons/WarningRounded";
import classnames from "classnames";
import React from "react";
import styles from "./Snackbar.module.scss";

export type SnackbarConfig = {
    id: string;
    icon?: any;
    text: string;
    success?: boolean;
    error?: boolean;
    warning?: boolean;
    disableAutoHide?: boolean;
    duration?: number;
};

type Props = {
    className?: string;
    snackbarShown?: boolean;
    autoHideDuration?: number;
    onClose?: (config: SnackbarConfig) => void;

    config: SnackbarConfig;
};

export default function Snackbar(props: Props) {
    const { config, snackbarShown, autoHideDuration= 2500 } = props;
    const { id, success, warning, error, icon, disableAutoHide, duration, text } = config;

    const className = classnames(
        styles.snackbar_content,
        {
            [styles.success]: success,
            [styles.error]: error,
            [styles.warning]: warning,
        },
        props.className,
    );

    let Icon = null;

    if (success) {
        Icon = CheckCircleIcon;
    } else if (warning) {
        Icon = WarningIcon;
    } else if (warning) {
        Icon = ErrorIcon;
    } else {
        Icon = InfoIcon;
    }

    if (icon) {
        Icon = icon;
    }

    const onClose = () => {
        props.onClose?.(config);
    };

    return (
        <MatSnackbar
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            className={styles.snackbar}
            open={!!snackbarShown}
            TransitionComponent={Fade}
            ClickAwayListenerProps={{ touchEvent: false, mouseEvent: false }}
            autoHideDuration={disableAutoHide ? null : duration || autoHideDuration}
            onClose={onClose}
            key={id}>
            <SnackbarContent
                className={className}
                aria-describedby={id}
                message={
                    <span id={id} className={styles.snackbar_content_message}>
                        {Icon ? <Icon /> : null}
                        <span>{text}</span>
                    </span>
                }
                action={[
                    <IconButton key={"close"} aria-label={"Close"} color={"inherit"} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>,
                ]}
            />
        </MatSnackbar>
    );
}
