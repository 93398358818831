/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 5/03/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */
import React from "react";
import Spinner from "../../widgets/spinner/Spinner";
import styles from "./Loading.module.scss";

export default function Loading() {
    return <Spinner vCenter hCenter white containerClassName={styles.loading} />;
}
