/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 12/02/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import Newsletter from "../../types/Newsletter";
import { Actions, Types } from "../actions/newsletters";

const initialState: Newsletter[] = [];

export type ActionTypes = ReturnType<Actions[keyof Actions]>;
export type ActionValueTypes<T extends Types> = ReturnType<Actions[T]>["payload"];

export default function newsletters(state = initialState, action: ActionTypes): Newsletter[] {
    switch (action.type) {
        case Types.SET_NEWSLETTERS: {
            const payload = action.payload! as ActionValueTypes<Types.SET_NEWSLETTERS>;
            return payload!;
        }
        default: {
            return state;
        }
    }
}
