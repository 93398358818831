/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 12/03/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import Subscription from "../../types/Subscription";
import { Actions, Types } from "../actions/subscriptions";

type State = {
    [key: string]: Subscription;
};

const initialState: State = {};

type ActionTypes = ReturnType<Actions[keyof Actions]>;
type ActionValueTypes<T extends Types> = ReturnType<Actions[T]>;

export default function subscriptions(state = initialState, action: ActionTypes): State {
    switch (action.type) {
        case Types.SET_SUBSCRIPTION: {
            const { payload } = action as ActionValueTypes<Types.SET_SUBSCRIPTION>;
            const subscription = payload;

            if (!subscription) {
                return state;
            }

            return {
                ...state,
                [subscription.user.id]: subscription,
            };
        }
        default: {
            return state;
        }
    }
}
