/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 5/03/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import {List} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import firebase from "firebase/app";
import "firebase/auth";
import React from "react";
import {Redirect, Route, Switch, useHistory} from "react-router-dom";
import useDispatch from "../../../hooks/useDispatch";
import {setUser} from "../../../redux/actions/user";
import TheSpinoffLogo from "../../../resources/images/js/TheSpinoffLogo";
import Button from "../../widgets/button/Button";
import styles from "./Dashboard.module.scss";
import Member from "./routes/member/Member";
import Members from "./routes/members/Members";
import NewsletterSettings from "./routes/newsletterSettings/NewsletterSettings";
import SubscriptionSettings from "./routes/subscriptionSettings/SubscriptionSettings";
import SubscriptionsOverview from "./routes/subscriptionsOverview/SubscriptionsOverview";

export default function Dashboard() {
    const dispatch = useDispatch();
    const history = useHistory();

    const onLogoutPress = async () => {
        firebase.auth().signOut();
        dispatch(setUser(null));
    };

    const onNavigatePress = (path: string) => {
        return (e: React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault();
            history.push(path);
        };
    };

    return (
        <div className={styles.dashboard}>
            <div className={styles.toolbar}>
                <div className={styles.toolbar_inner}>
                    <TheSpinoffLogo className={styles.logo} pink/>
                    <Button onClick={onLogoutPress} plain>
                        Log Out
                    </Button>
                </div>
            </div>
            <div className={styles.dashboard_inner}>
                <List className={styles.sidebar} component={"div"}>
                    <MenuItem component={"a"} onClick={onNavigatePress("/subscriptions")} href={"/subscriptions"}>
                        Subscriptions overview
                    </MenuItem>
                    {/*<MenuItem component={"a"} onClick={onNavigatePress("/earnings")} href={"/earnings"}>*/}
                    {/*    Earnings overview*/}
                    {/*</MenuItem>*/}
                    <MenuItem component={"a"} onClick={onNavigatePress("/members")} href={"/members"}>
                        Spinoff members
                    </MenuItem>
                    <MenuItem component={"a"} onClick={onNavigatePress("/subscription-settings")}
                              href={"/subscription-settings"}>
                        Subscription settings
                    </MenuItem>
                    <MenuItem component={"a"} onClick={onNavigatePress("/newsletter-settings")}
                              href={"/newsletter-settings"}>
                        Newsletter settings
                    </MenuItem>
                </List>
                <div className={styles.content}>
                    <Switch>
                        <Route path={"/subscriptions"} component={SubscriptionsOverview}/>
                        {/*<Route path={"/earnings"} component={Earnings} />*/}
                        <Route path={"/members/:userId"} component={Member}/>
                        <Route path={"/members"} component={Members}/>
                        <Route path={"/subscription-settings"} component={SubscriptionSettings}/>
                        <Route path={"/newsletter-settings"} component={NewsletterSettings}/>
                        <Redirect from={"/"} to={"/subscriptions"}/>
                    </Switch>
                </div>
            </div>
        </div>
    );
}
