/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 20/02/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import MuiDialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Form as FormikForm, Formik, FormikProps, FormikValues } from "formik";
import React, { PropsWithChildren, Ref, useEffect, useImperativeHandle, useState } from "react";
import Button from "../button/Button";
import ErrorBlock from "../errorBlock/ErrorBlock";
import styles from "../dialog/Dialog.module.scss";
import FormikInput from "../input/FormikInput";
import UsersAPI from "../../../redux/actions/api/user";
import { showSuccess } from "../../../redux/actions/snackbars";
import useDispatch from "../../../hooks/useDispatch";

export type DialogProps<FormValues> = PropsWithChildren<{
    dialogRef: Ref<DialogRef>;
    className?: string;
    validationSchema?: any;
    initialValues: FormValues;
    title?: string;
    userId: string;
    onClose?: (formikProps: FormikProps<FormValues>) => void;
}>;

export type DialogRef = {
    show: () => void;
    hide: () => void;
};

type FormProps<FormValues extends FormikValues = FormikValues> = DialogProps<FormValues> & {
    formikProps: FormikProps<FormValues>;
    shown: boolean;
    loading: boolean;
    setShown: (bool: boolean) => void;
    onSubmit: (v: FormValues) => void;
    error: string | null;
    setError: (error: string | null) => void;
};

function Form<FormValues extends FormikValues = FormikValues>(props: FormProps<FormValues>) {
    const {
        title,
        formikProps,
        shown,
        setShown,
        loading,
        error,
        setError,
    } = props;
    const { handleReset, isValid, values, handleSubmit } = formikProps;


    const onClose = () => {
        setShown(false);
        setTimeout(() => {
            handleReset();
            setError(null);
            props.onClose?.(formikProps);
        }, 165);
    };

    const onCancelPress = () => {
        handleReset();

        setShown(false);

    };
    return (
        <MuiDialog
            className={styles.dialog}
            open={shown}
            onClose={onClose}
            disableBackdropClick={loading}
            disableEscapeKeyDown={loading}>
            <FormikForm>
                <DialogTitle className={styles.title} style={{ width: 600 }}>{title}</DialogTitle>
                <DialogContent className={styles.content}>
                    <FormikInput name={"password"} placeholder={"New Password"} label={"New Password"} />
                    <FormikInput name={"confirm"} placeholder={"Confirm New Password"} label={"Confirm New Password"} />

                    <ErrorBlock error={error} />
                </DialogContent>
                <DialogActions className={styles.buttons}>
                    <div className={styles.buttonsRight}
                         style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                        <Button onClick={onCancelPress} plain disabled={loading}>
                            Cancel
                        </Button>
                        <Button disabled={!isValid} loading={loading} type={"submit"}>
                            Reset
                        </Button>
                    </div>
                </DialogActions>
            </FormikForm>
        </MuiDialog>
    );
}


export default function ResetPasswordOnBehalfDialog<FormValues extends FormikValues = FormikValues>(props: DialogProps<FormValues>) {
    const { dialogRef, userId } = props;
    const [shown, setShown] = useState(false);
    const [error, setError] = useState<null | string>(null);
    const [loading, setLoading] = useState(false);
    const [genPassword, setGenPassword] = useState("");
    const dispatch = useDispatch();
    useImperativeHandle(dialogRef, () => ({
        show: () => {
            setShown(true);
        },
        hide: () => {
            setShown(false);
        },
    }));

    useEffect(() => {
        if (shown) {
            setGenPassword(Math.random().toString(36).slice(-8));
        }
    }, [shown]);

    const initialValues = { password: genPassword, confirm: genPassword };
    const onSubmit = async (values: FormValues) => {
        setLoading(true);
        await UsersAPI.updateUserPWordAdmin(userId, values.password);
        setLoading(false);
        setShown(false);
        dispatch(showSuccess("Password reset successfully"));
    };

    const validate = (values: FormValues) => {
        const errors: { [key: string]: string | undefined } = {};
        if (values.password !== values.confirm) {
            errors.confirm = "Doesn't match";
        }
        return errors;
    };

    return (
        <Formik<FormikValues>
            onSubmit={onSubmit}
            validate={validate}
            enableReinitialize
            initialValues={initialValues}>
            {(formikProps) => (
                <Form
                    {...props}
                    loading={loading}
                    formikProps={formikProps}
                    shown={shown}
                    onSubmit={(v: FormValues) => onSubmit(v)}
                    setShown={setShown}
                    error={error}
                    setError={setError}
                />
            )}
        </Formik>
    );
}
