/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 12/02/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import { AxiosRequestConfig } from "axios";
import instance from "./instance";

export default async function del<T = any>(url: string, config?: AxiosRequestConfig): Promise<T> {
    const data = await instance.delete<T>(url, config);
    return data.data;
}
