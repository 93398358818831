/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 12/02/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import User from "../../types/User";
import UserAPI from "./api/user";
import createAction from "./helpers/createAction";
import wrapper from "./helpers/wrapper";

export enum Types {
    SET_USER = "user:SET_USER",
}

export const setUser = createAction<User | null>(Types.SET_USER);

export const getUser = wrapper(UserAPI.getUser, { action: setUser });

export const resetUserPWord = wrapper(UserAPI.updateUserPWordAdmin);

const Actions = {
    [Types.SET_USER]: setUser,
};
export type Actions = typeof Actions;
