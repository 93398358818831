/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 17/12/19.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */
import { useEffect, useRef } from "react";

export default function usePrevious<T = any>(props: T): T {
    const ref = useRef(props);
    useEffect(() => {
        ref.current = props;
    });

    return ref.current;
}
