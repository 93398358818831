/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 10/03/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import Dinero from "dinero.js";
import { DateTime } from "luxon";
import { Column } from "material-table";
import React, { useState } from "react";
import TransactionsAPI from "../../../../../../../redux/actions/api/transactions";
import Transaction from "../../../../../../../types/Transaction";
import DateRangeInput, { DateRange } from "../../../../../../widgets/dateRangeInput/DateRangeInput";
import PagingTable from "../../../../../../widgets/pagingTable/PagingTable";

const COLUMNS: Column<Transaction>[] = [
    {
        title: "Date",
        field: "created",
        render: ({ created }) => (created ? DateTime.fromISO(created).toFormat("dd/MM/yy") : "-"),
    },
    {
        title: "Name",
        field: "user.firstName",
        render: ({ user }) => (user ? `${user?.firstName} ${user?.lastName}` : "Not Supplied"),
        sorting: false,
    },
    {
        title: "Amount",
        field: "amount",
        render: ({ amount, currency }) => Dinero({ amount: amount || 0, currency: currency || "NZD" }).toFormat(),
    },
];

type Filter = NonNullable<Parameters<typeof TransactionsAPI.getTransactions>[0]>;

export default function OneOff() {
    const [filter, setFilter] = useState<Filter>({
        limit: 10,
        order: null,
        type: "ONE_OFF",
        status: "succeeded",
    });

    const onConfirmDateRangePress = (dateRange: DateRange) => {
        setFilter((state) => ({
            ...state,
            from: dateRange.from,
            to: dateRange.to,
        }));
    };

    return (
        <>
            <DateRangeInput onConfirm={onConfirmDateRangePress} />
            <PagingTable columns={COLUMNS} filter={filter} getData={TransactionsAPI.getTransactions} setFilter={setFilter} />
        </>
    );
}
