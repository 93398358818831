/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 12/03/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */
import User from "../types/User";

export default function formatName(user: User | null): string {
    if (!user) {
        return "Loading...";
    }

    const { firstName, lastName } = user;

    if (!firstName && !lastName) {
        return "Not Supplied";
    }

    return `${firstName || ""}${firstName && lastName ? " " : ""}${lastName || ""}`;
}
