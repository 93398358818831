/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 12/02/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import pickBy from "lodash/pickBy";
import firebase from "firebase/app";
import "firebase/auth";
import qs from "../../../../util/qs";

const axiosInstance: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
    paramsSerializer: (params: any) => {
        const newParams = pickBy(params, (value) => !!value && value !== "null");
        return qs.stringify(newParams);
    },
});

axiosInstance.interceptors.request.use(
    async (config: AxiosRequestConfig) => {
        if (config.headers.Authorization) {
            return config;
        }

        const newConfig = { ...config };
        newConfig.headers.Authorization = await firebase?.auth?.()?.currentUser?.getIdToken();

        return config;
    },
    (err: any) => {
        return Promise.reject(err);
    },
);

export default axiosInstance;
