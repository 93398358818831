/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 11/02/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import { AnyAction } from "redux";

export interface Action<T = unknown> extends AnyAction {
    payload: T | undefined;
}

export default function createAction<T extends unknown>(type: string) {
    return (payload?: T): Action<T> => {
        return {
            type: type,
            payload: payload,
        };
    };
}