/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 5/03/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import { Tab, Tabs } from "@material-ui/core";
import React from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import OneOff from "./routes/oneOff/OneOff";
import Subscriptions from "./routes/subscriptions/Subscriptions";
import styles from "./SubscriptionsOverview.module.scss";

export default function SubscriptionsOverview() {
    const history = useHistory();

    const onTabChange = (e: any, path: string) => {
        history.push(path);
    };

    return (
        <>
            <h3>Subscriptions overview</h3>
            <p className={styles.warning}>
                NB: New subscriptions are only visible in Stripe from October 2021. The list below will not be accurate from that
                point. Be sure to{" "}
                <a href={"https://dashboard.stripe.com/subscriptions"} target={"_blank"} rel={"noopener noreferrer"}>
                    check Stripe for other subscriptions
                </a>
                .
            </p>
            <Tabs value={history.location.pathname} onChange={onTabChange} className={styles.subscriptions_overview_nav}>
                <Tab
                    label={"Subscriptions"}
                    value={"/subscriptions"}
                    classes={{
                        selected: styles.tab_selected,
                    }}
                />
                <Tab
                    label={"One-off subscribers"}
                    value={"/subscriptions/one-off"}
                    classes={{
                        selected: styles.tab_selected,
                    }}
                />
            </Tabs>
            <div className={styles.content}>
                <Switch>
                    <Route path={"/subscriptions/one-off"} component={OneOff} />
                    <Route path={"/subscriptions"} component={Subscriptions} />
                </Switch>
            </div>
        </>
    );
}
