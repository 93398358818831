import LuxonUtils from "@date-io/luxon";
import { CssBaseline } from "@material-ui/core";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { StylesProvider, ThemeProvider } from "@material-ui/styles";
import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import configureStore from "../redux/store/configureStore";
import Main from "./Main";
import SnackbarContainer from "./widgets/snackbar/SnackbarContainer";
import Spinner from "./widgets/spinner/Spinner";

export const { store, persistor } = configureStore();

const theme = createMuiTheme({
    typography: {
        fontFamily: "neuzeit-grotesk, sans-serif",
    },
    palette: {
        primary: {
            main: "#FE9D8D",
        },
        secondary: {
            main: "#14B47C",
        },
    },
    spacing: 9,
    overrides: {
        MuiInputBase: {
            input: {
                height: 34,
            },
        },
        MuiInput: {
            root: {
                border: "1px solid #CDCDCD",
                borderRadius: 4,
                minWidth: 204,
            },
            input: {
                minHeight: 28,
                fontSize: 16,
                padding: "4px 9px",
            },
            formControl: {
                "label + &": {
                    marginTop: 20,
                },
            },
        },
        MuiFormControl: {
            root: {
                display: "flex",
            },
        },
        MuiInputAdornment: {
            root: {},
            positionStart: {
                marginRight: 0,
                marginLeft: 4,
            },
            positionEnd: {
                marginRight: 9,
                marginLeft: 0,
            },
        },
        MuiSelect: {
            select: {
                display: "flex",
                alignItems: "center",
                minWidth: 171,
            },
            selectMenu: {
                height: undefined,
            },
        },
        MuiInputLabel: {
            root: {
                fontFamily: "neuzeit-grotesk",
                fontWeight: "bold",
                fontSize: 20,
            },
        },
        MuiButton: {
            root: {
                fontFamily: "neuzeit-grotesk",
                padding: "4px 12px",
                fontSize: 18,
                textTransform: "none",
            },
            text: {
                padding: "4px 12px",
            },
            sizeSmall: {
                padding: "0 9px",
                fontSize: 16,
            },
        },
        MuiMenuItem: {
            root: {
                paddingLeft: 18,
                paddingRight: 18,
            },
        },
        MuiTabs: {
            indicator: {
                backgroundColor: "#FE9D8D",
            },
        },
    },
    props: {
        MuiInput: {
            disableUnderline: true,
        },
        MuiInputLabel: {
            shrink: true,
        },
    },
});



export default function App() {
    return (
        <Provider store={store}>
            <StylesProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <MuiPickersUtilsProvider utils={LuxonUtils}>
                        <PersistGate loading={<Spinner center />} persistor={persistor}>
                            <SnackbarContainer>
                                <Suspense fallback={<Spinner center />}>
                                    <CssBaseline />
                                    <Main />
                                </Suspense>
                            </SnackbarContainer>
                        </PersistGate>
                    </MuiPickersUtilsProvider>
                </ThemeProvider>
            </StylesProvider>
        </Provider>
    );
}
