/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 2019-06-06.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import { DatePicker, DatePickerProps } from "@material-ui/pickers";
import classnames from "classnames";
import { DateTime } from "luxon";
import React, { ReactElement } from "react";
import styles from "./DateInput.module.scss";

type Props = {
    className?: string;
    pickerClassName?: string;

    value: string | null;
    onChange: (date: string) => void;
} & Omit<DatePickerProps, "value" | "onChange">;

export default function DateInput(props: Props): ReactElement {
    const { disabled, InputProps, PopoverProps, ...otherProps } = props;
    const { value } = props;

    const className = classnames(styles.date_time_picker_wrapper, props.className);
    const pickerClassName = classnames(
        styles.date_time_picker,
        {
            [styles.disabled]: disabled,
        },
        props.pickerClassName,
    );

    const onChange = (date: DateTime) => {
        const formattedDate = date.toFormat("yyyy-MM-dd");
        props.onChange(formattedDate);
    };

    let dateTimeValue: DateTime | null = null;
    if (value) {
        dateTimeValue = DateTime.fromISO(value);
    }

    return (
        <div className={className}>
            <DatePicker
                variant={"inline"}
                format={"dd MMMM yyyy"}
                autoOk
                {...otherProps}
                value={dateTimeValue}
                onChange={onChange}
                className={pickerClassName}
                disabled={disabled}
                InputProps={{
                    disableUnderline: true,
                    ...InputProps,
                }}
                PopoverProps={{
                    className: styles.date_time_picker_popover,
                    ...PopoverProps,
                }}
            />
        </div>
    );
}
