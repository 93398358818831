/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 20/02/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import MuiDialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Form as FormikForm, Formik, FormikProps, FormikValues } from "formik";
import React, { PropsWithChildren, Ref, useImperativeHandle, useState } from "react";
import Button from "../button/Button";
import ErrorBlock from "../errorBlock/ErrorBlock";
import styles from "./Dialog.module.scss";

export type DialogProps<FormValues> = PropsWithChildren<{
    dialogRef: Ref<DialogRef>;
    className?: string;

    updating?: boolean;
    createAction: ((values: FormValues, formikHelpers: FormikProps<FormValues>) => Promise<any>) | null;
    updateAction?: ((values: FormValues, formikHelpers: FormikProps<FormValues>) => Promise<any>) | null;
    deleteAction?: ((values: FormValues, formikHelpers: FormikProps<FormValues>) => Promise<any>) | null;

    validationSchema?: any;
    initialValues: FormValues;
    title?: string;

    onClose?: (formikProps: FormikProps<FormValues>) => void;
}>;

export type DialogRef = {
    show: () => void;
    hide: () => void;
};

type FormProps<FormValues extends FormikValues = FormikValues> = DialogProps<FormValues> & {
    formikProps: FormikProps<FormValues>;
    shown: boolean;
    setShown: (bool: boolean) => void;
    deleting: boolean;
    setIsDeleting: (bool: boolean) => void;
    error: string | null;
    setError: (error: string | null) => void;
};

function Form<FormValues extends FormikValues = FormikValues>(props: FormProps<FormValues>) {
    const {
        children,
        title,
        updating,
        formikProps,
        shown,
        setShown,
        deleting,
        setIsDeleting,
        error,
        setError,
        createAction,
        updateAction,
        deleteAction,
    } = props;
    const { values, handleReset, isValid } = formikProps;

    const createUpdateText = updating ? "Update" : "Create";
    const createUpdateDeleteText = deleting ? "Confirm" : createUpdateText;

    const [loading, setLoading] = useState(false);

    const onDeletePress = () => {
        setIsDeleting(true);
    };

    const onClose = () => {
        setShown(false);
        setTimeout(() => {
            handleReset();
            setIsDeleting(false);
            setError(null);
            setLoading(false);
            props.onClose?.(formikProps);
        }, 165);
    };

    const onCancelPress = () => {
        handleReset();
        if (deleting) {
            setIsDeleting(false);
        } else {
            setShown(false);
        }
    };

    const onSubmit = async () => {
        setError(null);
        setLoading(true);
        try {
            if (deleting) {
                if (deleteAction) {
                    await deleteAction(values, formikProps);
                }
            } else if (updating) {
                if (updateAction) {
                    await updateAction(values, formikProps);
                }
            } else if (createAction) {
                await createAction(values, formikProps);
            }
            onClose();
        } catch (err) {
            setError(err?.message || err?.response?.data.message);
        }
        setLoading(false);
    };

    return (
        <MuiDialog
            className={styles.dialog}
            open={shown}
            onClose={onClose}
            disableBackdropClick={loading}
            disableEscapeKeyDown={loading}>
            <FormikForm onSubmit={onSubmit}>
                <DialogTitle className={styles.title}>{deleting ? "Confirm Delete" : title}</DialogTitle>
                <DialogContent className={styles.content}>
                    {deleting ? <span>Are you sure this cannot be undone?</span> : children}
                    <ErrorBlock error={error} />
                </DialogContent>
                <DialogActions className={styles.buttons}>
                    {updating && deleteAction && !deleting ? (
                        <Button onClick={onDeletePress} plain disabled={loading}>
                            Delete
                        </Button>
                    ) : (
                        <div />
                    )}
                    <div className={styles.buttonsRight}>
                        <Button onClick={onCancelPress} plain disabled={loading}>
                            Cancel
                        </Button>
                        <Button disabled={!isValid} loading={loading} red={deleting} onClick={onSubmit}>
                            {createUpdateDeleteText}
                        </Button>
                    </div>
                </DialogActions>
            </FormikForm>
        </MuiDialog>
    );
}

export default function Dialog<FormValues extends FormikValues = FormikValues>(props: DialogProps<FormValues>) {
    const { dialogRef, validationSchema, initialValues } = props;
    const [shown, setShown] = useState(false);
    const [deleting, setIsDeleting] = useState(false);
    const [error, setError] = useState<null | string>(null);

    useImperativeHandle(dialogRef, () => ({
        show: () => {
            setShown(true);
        },
        hide: () => {
            setShown(false);
        },
    }));

    const onSubmit = () => {};

    return (
        <Formik<FormValues>
            onSubmit={onSubmit}
            validationSchema={!deleting && validationSchema}
            enableReinitialize
            initialValues={initialValues}>
            {(formikProps) => (
                <Form
                    {...props}
                    formikProps={formikProps}
                    shown={shown}
                    setShown={setShown}
                    deleting={deleting}
                    setIsDeleting={setIsDeleting}
                    error={error}
                    setError={setError}
                />
            )}
        </Formik>
    );
}
