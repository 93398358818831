/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 14/02/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import firebase from "firebase/app";
import "firebase/auth";
import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import useAdminState from "../hooks/useAdminState";
import useDispatch from "../hooks/useDispatch";
import useMountEffect from "../hooks/useMountEffect";
import { getUser } from "../redux/actions/user";
import Nullable from "../types/Nullable";
import Dashboard from "./routes/dashboard/Dashboard";
import Loading from "./routes/loading/Loading";
import Login from "./routes/login/Login";
import nzPostAPI from "../redux/actions/api/nzPost";


export default function Main() {
    const dispatch = useDispatch();
    const [isLoggedIn] = useAdminState();
    const [loading, setLoading] = useState(true);

    const onAuthStateChanged = async (firebaseUser: Nullable<firebase.User>) => {
        if (firebaseUser) {
            try {
                await dispatch(getUser());
                nzPostAPI.getToken()

                setLoading(false);
            } catch (e) {
                console.log("error", e);
            }
        }
        setLoading(false);
    };

    useMountEffect(() => {
        firebase.auth().onAuthStateChanged(onAuthStateChanged);
    });

    return (
        <Router>
            <Switch>
                {loading && <Route path={"*"} component={Loading} />}
                {isLoggedIn ? <Route path={"*"} component={Dashboard} /> : <Route path={"*"} component={Login} />}
            </Switch>
        </Router>
    );
}
