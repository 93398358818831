/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 2019-06-06.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import { DatePickerProps } from "@material-ui/pickers";
import React, { ReactElement } from "react";
import useFormikField from "../../../hooks/useFormikField";
import DateInput from "./DateInput";

type Props = {
    name: string;
    className?: string;
    pickerClassName?: string;
} & Omit<DatePickerProps, "value" | "onChange">;

export default function FormikDateInput(props: Props): ReactElement {
    const { name, ...otherProps } = props;

    const { value, disabled, showError, helperText, formik } = useFormikField(props);

    const onChange = (date: string) => {
        formik.setFieldValue(name, date);
    };

    return (
        <DateInput {...otherProps} value={value} onChange={onChange} disabled={disabled} error={showError} helperText={helperText} />
    );
}
