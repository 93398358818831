/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 12/02/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import { AxiosRequestConfig } from "axios";
import instance from "./instance";

export default async function put<T = any>(url: string, putData?: any, config?: AxiosRequestConfig): Promise<T> {
    const data = await instance.put<T>(url, putData, config);
    return data.data;
}
