/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 9/03/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */
import ListResult from "../../../types/ListResult";
import Subscription from "../../../types/Subscription";
import get from "./util/get";

function getSubscriptions(params?: {
    limit?: number;
    interval?: string | null;
    dateField?: string | null;
    from?: string | null;
    to?: string | null;
    cursor?: string | null;
    order?: string | null;
    status?: string;
}): Promise<ListResult<Subscription>> {
    return get(`/v1/admin/subscriptions`, {
        params: params,
    });
}

const SubscriptionsAPI = {
    getSubscriptions: getSubscriptions,
};

export default SubscriptionsAPI;
