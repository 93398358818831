/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 11/03/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import Dinero from "dinero.js";
import { DateTime } from "luxon";
import { Column } from "material-table";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import SubscriptionsAPI from "../../../../../../../redux/actions/api/subscriptions";
import Subscription from "../../../../../../../types/Subscription";
import DateRangeFilter, { TDateRangeFilter } from "../../../../../../widgets/dateRangeFilter/DateRangeFilter";
import Input from "../../../../../../widgets/input/Input";
import PagingTable from "../../../../../../widgets/pagingTable/PagingTable";
import styles from "./Subscriptions.module.scss";

function UsersName({ userId, name }: { userId: string; name: string }) {
    const history = useHistory();

    const onPress = (e: React.MouseEvent) => {
        e.preventDefault();
        history.push(`/members/${userId}`);
    };

    return <span onClick={onPress} className={styles.table_link}>{name}</span>;
}

const COLUMNS: Column<Subscription>[] = [
    {
        title: "Date",
        field: "createdAt",
        render: ({ createdAt }: Subscription) => (createdAt ? DateTime.fromISO(createdAt).toFormat("dd/MM/yy") : "-"),
    },
    {
        title: "Name",
        field: "user.firstName",
        render: ({ user }: Subscription) => {
            return <UsersName userId={user?.id} name={user ? `${user?.firstName} ${user?.lastName}` : "Not Supplied"} />;
        },
    },
    {
        title: "Amount",
        field: "amount",
        render: ({ amount, currency }) => Dinero({ amount: amount || 0, currency: currency || "NZD" }).toFormat(),
    },
    {
        title: "Status",
        field: "status",
    },
    {
        title: "Active Stripe Sub",
        field: "user.stripeSubscriptionId",
        render: ({ user }: Subscription) => {
            return (
                <a href={`https://dashboard.stripe.com/subscriptions/${user?.stripeSubscriptionId}`}>{user?.stripeSubscriptionId}</a>
            );
        },
    },
];

type Filter = NonNullable<Parameters<typeof SubscriptionsAPI.getSubscriptions>[0]>;

export default function Subscriptions() {
    const [filter, setFilter] = useState<Filter>({
        limit: 10,
        order: null,
        dateField: null,
        from: null,
        to: null,
        status: "null",
        interval: "null",
    });

    const onStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const status = e.target.value;
        setFilter((oldState) => ({
            ...oldState,
            status: status,
        }));
    };

    const onIntervalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const interval = e.target.value;
        setFilter((oldState) => ({
            ...oldState,
            interval: interval,
        }));
    };

    const onConfirmDateRangePress = (dateRangeFilter: TDateRangeFilter) => {
        setFilter((oldState) => ({
            ...oldState,
            dateField: dateRangeFilter.type,
            from: dateRangeFilter.from,
            to: dateRangeFilter.to,
        }));
    };

    return (
        <>
            <div className={styles.filter}>
                <Input
                    label={"Status"}
                    value={filter.status}
                    options={[
                        {
                            label: "All",
                            value: "null",
                        },
                        {
                            label: "Active",
                            value: "ACTIVE",
                        },
                        {
                            label: "Cancelled",
                            value: "CANCELLED",
                        },
                    ]}
                    onChange={onStatusChange}
                />
                <Input
                    label={"Interval"}
                    value={filter.interval}
                    options={[
                        {
                            label: "All",
                            value: "null",
                        },
                        {
                            label: "Month",
                            value: "MONTH",
                        },
                        {
                            label: "Year",
                            value: "YEAR",
                        },
                    ]}
                    onChange={onIntervalChange}
                />
            </div>
            <DateRangeFilter
                onConfirm={onConfirmDateRangePress}
                options={[
                    {
                        label: "Created",
                        value: "createdAt",
                    },
                    {
                        label: "Next Charge",
                        value: "nextChargeAt",
                    },
                    {
                        label: "Last Charge",
                        value: "lastChargeAt",
                    },
                    {
                        label: "First Charge",
                        value: "firstChargeAt",
                    },
                    {
                        label: "Charge Failed",
                        value: "chargeFailedAt",
                    },
                    {
                        label: "Cancelled",
                        value: "endedAt",
                    },
                ]}
            />
            <PagingTable columns={COLUMNS} filter={filter} getData={SubscriptionsAPI.getSubscriptions} setFilter={setFilter} />
        </>
    );
}
