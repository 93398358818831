export enum USER_ROLE {
    USER = 1,
    MEMBER = 2,
    ADMIN = 100000,
}

export enum USER_STATUS {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE",
    DELETED = "DELETED",
    ADMIN_DELETED = "ADMIN_DELETED",
}

export enum NEWSLETTER_SUBSCRIPTION_STATUS {
    SUBSCRIBE = "SUBSCRIBE",
    UNSUBSCRIBE = "UNSUBSCRIBE",
}

export default interface User {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    stripeCustomerId: string;
    shopifyCustomerId: number;
    dateOfBirth: string;
    fullAmountDonated: number;
    role: USER_ROLE;
    status: USER_STATUS;
    memberSince: string;
    createdAt: string;
    updatedAt: string;
    pressPatronCustomer: boolean;
    sendMerchandiseOnMilestones: boolean;
    newsletterSubscriptions: {
        [key: string]: NEWSLETTER_SUBSCRIPTION_STATUS;
    };
    milestoneMerchSent: boolean;
    stripeSubscriptionId: string;
    notes: string;
}
