/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 11/03/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import {useField, useFormikContext} from "formik";

type ExtraProps = {
    name: string;
    disabled?: boolean;
    helperText?: string;
};

export default function useFormikField<P extends ExtraProps>(props: P) {

    const formik = useFormikContext();
    const {isSubmitting} = formik;
    const fieldProps = useField<string>(props.name);

    const [field, meta, helpers] = fieldProps;
    const {value} = field;

    const fieldError = meta.error;
    const showError = meta.touched && !!fieldError;
    const helperText = showError ? fieldError : props.helperText;

    const disabled = props.disabled ?? isSubmitting;

    return {
        value: value,
        helperText: helperText,
        showError: showError,
        disabled: disabled,
        field: field,
        meta: meta,
        helpers: helpers,
        formik: formik,
    };
}