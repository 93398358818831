/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 12/03/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import Subscription from "../../types/Subscription";
import UsersAPI from "./api/users";
import createAction from "./helpers/createAction";
import wrapper from "./helpers/wrapper";

export enum Types {
    SET_SUBSCRIPTION = "subscriptions:SET_SUBSCRIPTION",
}

export const setSubscription = createAction<Subscription>(Types.SET_SUBSCRIPTION);

export const createUserSubscription = wrapper(UsersAPI.createUserSubscription, {
    action: setSubscription,
});

export const getUserSubscription = wrapper(UsersAPI.getUserSubscription, {
    action: setSubscription,
});

export const updateUserSubscription = wrapper(UsersAPI.updateUserSubscription, {
    action: setSubscription,
});

export const cancelUserSubscription = wrapper(UsersAPI.cancelUserSubscription, {
    action: setSubscription,
});

const Actions = {
    [Types.SET_SUBSCRIPTION]: setSubscription,
};
export type Actions = typeof Actions;
