/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 11/03/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */
import Address from "../../../types/Address";
import Subscription from "../../../types/Subscription";
import User from "../../../types/User";
import del from "./util/del";
import get from "./util/get";
import post from "./util/post";
import put from "./util/put";

function getUsers(params: { searchType?: string | null; searchText?: string | null; limit?: number }) {
    return get(`/v1/users`, {
        params: params,
    });
}

function getUser(userId: string): Promise<User> {
    return get(`/v1/users/${userId}`);
}

function updateUser(userId: string, user: User): Promise<User> {
    return put(`/v1/users/${userId}`, user);
}

function getUserTotalToDate(userId: string): Promise<number> {
    return get(`/v1/users/${userId}/total`);
}

function getUserAddresses(userId: string): Promise<Address[]> {
    return get(`/v1/users/${userId}/address`);
}

function createUpdateUserAddresses(userId: string, addressDTO: Address): Promise<Address[]> {
    return post(`v1/admin/users/${userId}/address`, addressDTO);
}

function getUserSubscription(userId: string): Promise<Subscription> {
    // Return a promise that gets the v1 API (old style subscription) and returns result, OR
    // gets the v2 API (stripe subscription).
    // NB: Both might return nothing, which means no subscription is present.
    return get(`/v1/users/${userId}/subscription`).then((sub) => {
        if (!sub) {
            return get(`/v2/users/${userId}/subscription`);
        }
        return sub;
    });
}

function updateUserSubscription(userId: string, subscription: Subscription): Promise<Subscription> {
    const data = {
        ...subscription,
        user: null,
    };
    // v2 API will move a user from internal to stripe subscription if an internal sub exists.
    return put(`/v2/users/${userId}/subscription`, data);
}

function createUserSubscription(userId: string, subscription: Subscription): Promise<Subscription> {
    const data = {
        ...subscription,
        user: null,
    };
    // move to v2 to create subs directly in Stripe
    return post(`v2/users/${userId}/subscription`, data);
}

function cancelUserSubscription(userId: string): Promise<Subscription> {
    // Attempt the cancellation on v1. This will return a 400 error if there is no Classic subscription
    // if this is the case, then attempt cancellation of Stripe subscription via v2 API
    return del(`/v1/users/${userId}/subscription`)
        .then((sub) => sub)
        .catch(() => {
            return del(`/v2/users/${userId}/subscription`)
        });
}

function createUserAdmin(user: { firstName: string; lastName: string; email: string }): Promise<User> {
    return post(`/v1/admin/user`, user);
}

function updateUserAdmin(userId: string, user: User): Promise<User> {
    return put(`v1/admin/users/${userId}`, user);
}


const UsersAPI = {
    getUsers: getUsers,
    getUser: getUser,
    updateUser: updateUser,
    getUserTotalToDate: getUserTotalToDate,
    getUserAddresses: getUserAddresses,
    getUserSubscription: getUserSubscription,
    createUserSubscription: createUserSubscription,
    updateUserSubscription: updateUserSubscription,
    cancelUserSubscription: cancelUserSubscription,
    createUserAdmin: createUserAdmin,
    createUpdateUserAddress: createUpdateUserAddresses,
};

export default UsersAPI;
