/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 12/02/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import { applyMiddleware, createStore } from "redux";
import { persistStore } from "redux-persist";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import root from "../reducers/root";

export default function configureStore() {
    const store = createStore(root, composeWithDevTools(applyMiddleware(thunk)));

    const persistor = persistStore(store);

    return {
        store: store,
        persistor: persistor,
    };
}
