/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 12/03/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import User from "../../types/User";
import { Actions, Types } from "../actions/users";

type State = {
    [key: string]: User;
};

const initialState: State = {};

type ActionTypes = ReturnType<Actions[keyof Actions]>;
type ActionValueTypes<T extends Types> = ReturnType<Actions[T]>;

export default function users(state = initialState, action: ActionTypes): State {
    switch (action.type) {
        case Types.SET_USER: {
            const { payload } = action as ActionValueTypes<Types.SET_USER>;
            const user = payload!;

            return {
                ...state,
                [user.id]: user,
            };
        }
        default: {
            return state;
        }
    }
}
