/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 11/02/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import { PersistConfig } from "redux-persist/es/types";
import storage from "redux-persist/lib/storage";
import newsletters from "./newsletters";
import snackbars from "./snackbars";
import subscriptions from "./subscriptions";
import user from "./user";
import users from "./users";

const reducers = {
    subscriptions: subscriptions,
    snackbars: snackbars,
    user: user,
    users: users,
    newsletters: newsletters,
};

const PERSIST_CONFIG: PersistConfig<any> = {
    key: "root",
    storage: storage,
    whitelist: ["settings"],
};

const root = combineReducers(reducers);
const persistedReducer = persistReducer(PERSIST_CONFIG, root);

export type ApplicationState = ReturnType<typeof root>;

export default persistedReducer;
