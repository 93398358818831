/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 5/03/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import Dinero from "dinero.js";
import firebase from "firebase/app";
import "firebase/auth";
import { DateTime } from "luxon";
import { Column } from "material-table";
import React, { useRef, useState } from "react";
import * as Yup from "yup";
import useDispatch from "../../../../../hooks/useDispatch";
import UsersAPI from "../../../../../redux/actions/api/users";
import { createUserAdmin, setUser } from "../../../../../redux/actions/users";
import RouteProps from "../../../../../types/RouteProps";
import User from "../../../../../types/User";
import Button from "../../../../widgets/button/Button";
import Dialog, { DialogRef } from "../../../../widgets/dialog/Dialog";
import Header from "../../../../widgets/header/Header";
import FormikInput from "../../../../widgets/input/FormikInput";
import PagingTable from "../../../../widgets/pagingTable/PagingTable";
import SearchInput, { Search } from "../../../../widgets/searchInput/SearchInput";

const COLUMNS: Column<User>[] = [
    // {
    //     title: "ID",
    //     field: "id",
    // },
    {
        title: "Name",
        field: "firstName",
        render: ({ firstName, lastName }) => (firstName || lastName ? `${firstName || ""} ${`${lastName}` || ""}` : "Not Supplied"),
        sorting: false,
    },
    {
        title: "Email",
        field: "email",
    },
    {
        title: "Member Since",
        field: "createdAt",
        render: ({ createdAt }) => {
            return DateTime.fromISO(createdAt).toFormat("DD");
        },
    },
    {
        title: "Total Donated",
        field: "fullAmountDonated",
        render: ({ fullAmountDonated }) => {
            return Dinero({ amount: fullAmountDonated || 0, currency: "NZD" }).toFormat();
        },
    },
    {
        title: "Status",
        field: "status",
    },
];

type Filter = NonNullable<Parameters<typeof UsersAPI.getUsers>[0]>;

type CreateUserFormValues = {
    firstName: string;
    lastName: string;
    email: string;
};

const createUserValidationSchema = Yup.object<CreateUserFormValues>({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    email: Yup.string()
        .email("Invalid Email")
        .required("Required"),
});

export default function Members(props: RouteProps) {
    const { history } = props;
    const dispatch = useDispatch();
    const createUserDialog = useRef<DialogRef>(null);
    const [filter, setFilter] = useState<Filter>({
        limit: 10,
        searchText: null,
    });

    const onConfirmSearchPress = (search: Search) => {
        setFilter((state) => ({
            ...state,
            searchType: search.type,
            searchText: search.text,
        }));
    };

    const onUserRowPress = (e: React.MouseEvent, user: User) => {
        const { id } = user;
        dispatch(setUser(user));
        history.push(`/members/${id}`, { user: user });
    };

    const onCreateUserPress = () => {
        createUserDialog.current?.show();
    };

    const createUserAction = async (values: CreateUserFormValues) => {
        const user = await dispatch(createUserAdmin(values));
        const { id } = user;
        dispatch(setUser(user));
        await firebase.auth().sendPasswordResetEmail(user.email);
        history.push(`/members/${id}`, { user: user });
    };

    return (
        <>
            <Header action={<Button onClick={onCreateUserPress}>Create User</Button>}>Spinoff Members overview</Header>

            <SearchInput
                options={[
                    {
                        label: "Email",
                        value: "email",
                    },
                    {
                        label: "User ID",
                        value: "id",
                    },
                    {
                        label: "Stripe ID",
                        value: "stripeId",
                    },
                    {
                        label: "First Name",
                        value: "firstName",
                    },
                    {
                        label: "Last Name",
                        value: "lastName",
                    },
                ]}
                onConfirm={onConfirmSearchPress}
            />
            <PagingTable
                onRowClick={onUserRowPress}
                columns={COLUMNS}
                filter={filter}
                getData={UsersAPI.getUsers}
                setFilter={setFilter}
            />

            <Dialog<CreateUserFormValues>
                title={"Create User"}
                dialogRef={createUserDialog}
                createAction={createUserAction}
                validationSchema={createUserValidationSchema}
                initialValues={{
                    firstName: "",
                    lastName: "",
                    email: "",
                }}>
                <FormikInput name={"firstName"} label={"First Name"} />
                <FormikInput name={"lastName"} label={"Last Name"} />
                <FormikInput name={"email"} label={"Email"} />
            </Dialog>
        </>
    );
}
