/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 5/03/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */
import Dinero from "dinero.js";
import MaterialTable, { Column } from "material-table";
import React, { useRef, useState } from "react";
import useMountEffect from "../../../../../hooks/useMountEffect";
import SubscriptionOptionsAPI from "../../../../../redux/actions/api/subscriptionOptions";
import SubscriptionOption, { SUBSCRIPTION_OPTION_PERIOD, SUBSCRIPTION_OPTION_TYPE } from "../../../../../types/SubscriptionOption";
import Button from "../../../../widgets/button/Button";
import FormikCheckBox from "../../../../widgets/checkBox/FormikCheckBox";
import Dialog, { DialogRef } from "../../../../widgets/dialog/Dialog";
import Header from "../../../../widgets/header/Header";
import FormikInput from "../../../../widgets/input/FormikInput";
import styles from "../../../../widgets/pagingTable/PagingTable.module.scss";

const COLUMNS: Column<SubscriptionOption>[] = [
    {
        title: "Period",
        field: "period",
    },
    {
        title: "Type",
        field: "type",
    },
    {
        title: "Amount",
        field: "amount",
        render: ({ amount }) => Dinero({ amount: amount, currency: "NZD" }).toFormat(),
    },
    {
        title: "Preselected",
        field: "preselected",
        render: ({ preselected }) => (preselected ? "Yes" : "No"),
    },
    {
        title: "Status",
        field: "status",
    },
];

type FormValues = Omit<SubscriptionOption, "id" | "status"> & { id: number | null };

export default function SubscriptionSettings() {
    const [loading, setLoading] = useState(true);
    const [subscriptionOptions, setSubscriptionOptions] = useState<SubscriptionOption[]>([]);
    const [selectedSubscriptionOption, setSubscriptionOption] = useState<SubscriptionOption | null>(null);
    const dialogRef = useRef<DialogRef>(null);

    useMountEffect(async () => {
        const options = await SubscriptionOptionsAPI.getSubscriptionOptions();
        console.log("options", options);
        setSubscriptionOptions(options);
        setLoading(false);
    });

    const onCreateOptionPress = () => {
        dialogRef.current?.show();
    };

    const createUpdateAction = async (values: FormValues) => {
        const options = await SubscriptionOptionsAPI.createOrUpdateSubscriptionOptions(values);
        setSubscriptionOptions(options);
    };

    const deleteAction = async () => {
        if (!selectedSubscriptionOption) {
            return;
        }
        const options = await SubscriptionOptionsAPI.deleteSubscriptionOption(selectedSubscriptionOption.id);
        setSubscriptionOptions(options);
    };

    const onRowPress = (e: any, subscriptionOption: SubscriptionOption) => {
        setSubscriptionOption(subscriptionOption);
        dialogRef.current?.show();
    };

    const onClose = () => {
        setSubscriptionOption(null);
    };

    return (
        <>
            <Header action={<Button onClick={onCreateOptionPress}>Create</Button>}>Subscription Options</Header>
            <MaterialTable
                columns={COLUMNS}
                data={subscriptionOptions}
                isLoading={loading}
                components={{
                    Container: (ContainerProps: any) => <div {...ContainerProps} className={styles.paging_table} />,
                }}
                onRowClick={onRowPress}
                options={{
                    toolbar: false,
                    draggable: false,
                    paging: false,
                    search: false,
                }}
            />

            <Dialog<FormValues>
                title={selectedSubscriptionOption ? "Update Subscription Option" : "Create Subscription Option"}
                updating={!!selectedSubscriptionOption}
                dialogRef={dialogRef}
                onClose={onClose}
                createAction={createUpdateAction}
                updateAction={createUpdateAction}
                deleteAction={selectedSubscriptionOption ? deleteAction : null}
                initialValues={
                    selectedSubscriptionOption || {
                        id: null,
                        amount: 0,
                        preselected: false,
                        period: SUBSCRIPTION_OPTION_PERIOD.MONTHLY,
                        type: SUBSCRIPTION_OPTION_TYPE.SUBSCRIPTION,
                    }
                }>
                <FormikInput name={"amount"} label={"Amount"} money />
                <FormikCheckBox name={"preselected"} text={"Preselected"} />
                <FormikInput
                    name={"period"}
                    label={"Period"}
                    options={[
                        {
                            label: "Monthly",
                            value: "MONTHLY",
                        },
                        {
                            label: "Annually",
                            value: "YEARLY",
                        },
                        {
                            label: "One Time",
                            value: "ONE_OFF",
                        },
                    ]}
                />
                <FormikInput
                    name={"type"}
                    label={"Type"}
                    options={[
                        {
                            label: "Subscription",
                            value: "SUBSCRIPTION",
                        },
                        {
                            label: "Pay What You want",
                            value: "PAY_WHAT_YOU_WANT",
                        },
                    ]}
                />
            </Dialog>
        </>
    );
}
